import React from "react";

import Logos from '../../components/Logos/Logos';
import Rating from '../../components/Rating/Rating';
import ProjectsList from '../../components/Projectfeed/Projectfeed';
import Slider from '../../components/Slider/Slider';
import Brands from '../../components/Brands/Brands';
import Testimonials from '../../components/Testimonials/Testimonials';
import Marquee from '../../components/Marquee/Marquee';
import Faqs from '../../components/Faqs/Faqs';
import SEO from '../../components/SEO/SEO';

import News from '../../components/News/News';
import constants from '../../constants.json';


const Home = () => {
    return (
        <>
            <SEO
                title="Top-tier websites for brands and entrepreneurs – VPixel CO"
                description="We design and build marketing websites to help grow and strengthen brands, entrepreneurs, and businesses worldwide."
                ogImage="https://static.vpixel.co/OG-v2-Final-EN.jpg"
                favicon="https://static.vpixel.co/favicon-32x32.png"
                appleFavicon="https://static.vpixel.co/apple-touch-icon.png"
            />

            <section style={{ backgroundImage: 'url(/images/hero-background-2.svg?v=1.1)' }} id='about' className="about-us section nostatic">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="title flex-column d-flex">
                                <Rating />
                                <h1 className="h-title title-gradient">A web design crew focused on <span className='color-yellow'>quality and creativity.</span></h1>
                            </div>
                        </div>
                        <div className="col-12">
                            <p className='about-us__description'>
                                We're a team of talented <span>designers <img src="/images/people/girl.webp" alt="" width="29" /></span>,
                                <span>developers <img src="/images/people/erick.webp" alt="" width="35" /></span>, and
                                <span> marketing strategists <img src="/images/people/mark.webp" alt="" width="40" /></span>
                                working remotely from Barranquilla and Bogotá, Colombia 🇨🇴
                            </p>
                            <p>Taking a thoughtful, relaxed approach to our work, focusing on quality over speed. Rather than chasing fast growth or constant expansion, we choose to work on meaningful projects at our own pace, delivering exceptional digital experiences for businesses and agencies alike.</p>

                        </div>
                    </div>
                </div>
            </section>

            <News />

            <div className="logos-section">
                <div className="container">
                    <Logos />
                </div>
            </div>

            <section className="info">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <h2 className="title-gradient">Oh yes – we already know the <span className="color-yellow">song...</span></h2>
                                <p className="lead">Your website or app is not driving <span className="color-yellow">enough clients and revenue to your business <img src="/images/people/mad.webp" alt="" width="32" /></span>, and you know that this is the real challenge, but also, you could have:</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="grid grid-3">
                                <div className="grid-item d-flex flex-column">
                                    <img src="/images/question-scribble.svg" alt="" />
                                    <h3>Lost in the <span className='color-yellow'>competition</span></h3>
                                    <p>Amidst fierce competition, it's challenging to differentiate your brand when everyone is vying for attention.</p>
                                </div>
                                <div className="grid-item d-flex flex-column">
                                    <img src="/images/sad-scribble.svg" alt="" />
                                    <h3>Leads not <span className='color-yellow'>interacting</span></h3>
                                    <p>Having difficulty converting website visitors into leads? Your strategies may be failing to resonate with your audience.</p>
                                </div>
                                <div className="grid-item d-flex flex-column">
                                    <img src="/images/onboarding-scribble.svg" alt="" />
                                    <h3>Conversion <span className='color-yellow'>challenges</span></h3>
                                    <p>Acquiring clicks is only the beginning. The true test is in transforming those clicks into loyal customers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="fix">
                <div className="container">
                    <div className="fix-container">
                        <div className="info">
                            <div className="title">
                                <h2 className="title-gradient">But hey! we can fix this <span className="color-yellow">easily <img id='girl-2' src="/images/people/girl-2.webp" alt="" width="40" /> ⚡️</span></h2>
                                <p className="lead">What your business truly needs is a website that serves as a 24/7 sales representative, converting visitors into customers, and we achieve this through:</p>
                            </div>
                        </div>
                        <div className="cards">
                            <Slider />
                        </div>
                        <div className="cta">
                            <img src="/images/people/happy.webp" alt="" width="50" />
                            <a href={constants.site.contactLink} aria-label="Book a Call Now" className="button">Book a Call Now</a>
                        </div>
                    </div>
                </div>
            </section>

            <section id='portfolio' className="projects">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <div>
                                    <span className="subtitle">We can create amazing things together</span>
                                    <h2 className="title-gradient">Explore our most <span className="color-yellow">recent projects</span></h2>
                                </div>
                                <p className='lead'>
                                    From Colombia to Australia and beyond, our solutions have helped numerous companies, primarily entrepreneurs, by providing value, quality, and scalable websites, applications, and landing pages.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="projects__grid">
                                <div className="text">
                                    <div className="info d-flex flex-column">
                                        <h3>Creative work that drives amazing results <span>for our clients</span></h3>
                                        <Rating />
                                    </div>
                                </div>
                                <ProjectsList />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tech">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <div>
                                    <span className="subtitle">Transforming pixels and code to amazing products</span>
                                    <h2 className="title-gradient">Technology to create <span className="color-yellow">magical results</span></h2>
                                </div>
                                <p className="lead">
                                    Using the latest technologies to create Landing Pages, Marketing Sites, eCommerce stores and applications that are both beautiful and functional.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-10">
                            <Brands />
                        </div>
                    </div>
                </div>
            </section>

            <div className="marquee__container">
                <Marquee />
            </div>

            <Testimonials />

            <section className="services">
                <div id="services" style={{ position: 'relative', top: '-150px' }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <div>
                                    <span className="subtitle">Still not sure what we do?</span>
                                    <h2 className="title-gradient">We craft websites for companies <span className="color-yellow">around the world</span></h2>
                                </div>
                                <p className="lead">
                                    From visually stunning designs to robust WordPress development, we offer a comprehensive range of services tailored to meet the diverse needs of businesses across various industries.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="services__content d-flex flex-column">
                                <div className="side">
                                    {['Landing Pages', 'Marketing Websites', 'Corporate Websites', 'E-Commerce Stores', 'Branding & Logo Design', 'Multi-language Websites', 'UI/UX Design', 'Sales Funnels Tech', 'Frontend Development'].map((item, index) => (
                                        <span key={index} className="item nostatic">{item}</span>
                                    ))}
                                </div>

                                <div className="middle">
                                    <h2 className="title-gradient">And for several <span className='color-yellow'>markets and industries</span></h2>
                                </div>

                                <div className="side">
                                    {['SaaS', 'Startups', 'Entrepreneurs', 'Health Care', 'Real Estate', 'B2B Companies', 'Transport & Logistics', 'Legal', 'Non-Profit Organizations', 'Government', 'Marketing Agencies'].map((item, index) => (
                                        <span key={index} className="item nostatic">{item}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Faqs />

            <section id='contact' className="call">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex flex-column">
                            <div className="title">
                                <span className="subtitle">Convinced? Let's take the next step</span>
                                <h2 className="title-gradient">Claim your FREE 15-min <span className="color-yellow">Strategy Call</span></h2>
                            </div>
                            <div className="actions">
                                <a href={constants.site.contactLink} aria-label="Book a Call Today!" className="button">Book a Call Today!</a>
                                <Rating />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
