// import { span } from 'framer-motion/client';
import React from 'react';
import { translations } from '../../translations/languages';
import projectsData from './Projects.json';

const ProjectsList = ({ language = 'en' }) => {
    const t = translations[language];

    return (
        <div className="projects-list d-flex flex-column">
            {projectsData.map((project) => (
                <div key={project.id} className="project-item d-flex flex-column">
                    <figure className="nostatic" style={{ backgroundImage: `url(${project.imageUrl})` }}></figure>

                    <div className="project-item__info">
                        <div className="d-flex info">
                            <h3 className=''>{project.title}</h3>
                            <div className="tags">
                                <span className="tag">{project.year}</span>
                                <span className="tag">{project.industry}</span>
                            </div>
                        </div>
                        <div className="services">
                            {project.services.length === 1 ? (
                                <span>{project.services[0]}</span>
                            ) : (
                                project.services.map((service, index) => (
                                    <span key={service}>
                                        {service}
                                        {index < project.services.length - 1 ? ', ' : ''}
                                    </span>
                                ))
                            )}
                        </div>
                    </div>

                    {project.collaboratedWith && (
                        <div className="collab">
                            <div dangerouslySetInnerHTML={{ __html: project.collaboratedWith }} />
                        </div>
                    )}
                    <a target='_blank' rel="noreferrer" aria-label="See this project LIVE" href={project.liveUrl} className="button button--bordered">{t.seeThisLive}</a>
                </div>
            ))}
        </div>
    );
};

export default ProjectsList;
