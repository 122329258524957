import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';

const slides = [
    {
        image: "/images/cards/1.webp",
        title: "Great technology with <span class='color-yellow'>low pricing</span>",
        description: "<p>We design experiences that convert visitors into customers, guiding users to actions like making a purchase, downloading content, or registering.</p><p><strong>Thanks to our location in Colombia</strong>, we offer competitive pricing and high-quality technology, leveraging the value of the USD to deliver top-tier results at a fraction of the cost.</p>"
    },
    {
        image: "/images/cards/5.webp",
        class: "highlighted",
        title: "Creating websites <span class='color-yellow'>fast and easy</span> with AI",
        description: "<p>We co-develop each website with Artificial Intelligence (AI) for higher quality and resource availability.</p><p>If you lack photos, we use Midjourney to create them, and if you need more text, we generate it with ChatGPT Plus (ChatGPT 4).</p><p>This ensures we design a powerful sales tool for you at an affordable price.</p>"
    },
    {
        image: "/images/cards/2.webp",
        title: "A <span class='color-yellow'>ROI-Focused</span> mindset in every project",
        description: "<p>Stop wasting money on inefficient strategies. A Landing Page allows you to precisely track and analyze your visitors' behavior, enabling you to identify what works and what doesn't.</p><p>With this information, you can optimize your page and achieve better results, maximizing your return on investment (ROI).</p>"
    },
    {
        image: "/images/cards/3.webp",
        title: "Empowering the <span class='color-yellow'>voice &amp; tone</span> of your brand",
        description: "<p>With a specifically designed website, we will create a memorable experience aligned with your brand, from the attractive design to the clear and concise messaging.</p><p>We will highlight the unique advantages of your product or service, differentiating you from the competition and leaving a lasting impression on your visitors.</p>"
    },
    {
        image: "/images/cards/4.webp",
        title: "Relevant data from <span class='color-yellow'>your visitors &amp; clients</span>",
        description: "<p>A specifically designed website is a powerful tool for collecting valuable information about your visitors, capturing their interests, needs, and preferences through strategic forms, analytical tools, and demographic data.</p><p>This deep knowledge facilitates strategic decision-making in marketing and sales, enabling effective personalization and better targeting for commercial success.</p>"
    },
];

const Slider = () => {
    const breakpoints = {
        320: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        1024: { slidesPerView: 3 },
        1400: { slidesPerView: 3 }
    };

    return (
        <Swiper
            navigation={true}
            modules={[Navigation]}
            spaceBetween={15}
            breakpoints={breakpoints}
        >
            {slides.map(({ image, title, description, class: slideClass }, index) => (
                <SwiperSlide key={index}>
                    <div className={`slide-content d-flex flex-column ${slideClass || ''}`}>
                        <figure className="nostatic" style={{ backgroundImage: `url(${image})` }} />
                        <h3 dangerouslySetInnerHTML={{ __html: title }} />
                        <span dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Slider;